import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import DrugInfo from 'components/drug-info/drug-info.component';
import { OrderTracker } from 'components/order-tracker';

import { accountHasInsuranceSelector } from 'state/account/account.selectors';
import { cancelPrescriptionRoutine } from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { resetDrugDescription } from 'state/drug/drug.reducers';
import { drugDescriptionRoutine } from 'state/drug/drug.routines';
import { drugDescriptionSelector } from 'state/drug/drug.selectors';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

import PrescriptionCardIcon from '../prescriptions-card/prescription-card.icon';
import { PrescriptionCardProps, StatusProps } from '../prescriptions-card/types';
import './prescription-card-modal.style.scss';

export interface PrescriptionCardModalProps {
    fullPayload: PrescriptionObjectPayload;
    prescriptionName: PrescriptionCardProps['prescriptionName'];
    rxNumber: PrescriptionCardProps['rxNumber'];
    orderStatus: PrescriptionCardProps['orderStatus'];
    refillsLeft: PrescriptionCardProps['refillsLeft'];
    ctas?: PrescriptionCardProps['ctas'];
    rxDisplayStatuses: StatusProps[];
    orderDisplayStatuses: StatusProps[];
    setIsRxBusy: React.Dispatch<React.SetStateAction<boolean>>;
    orderSubStatus?: string | null;
    isTrackOrder: boolean;
}

const PrescriptionCardModal = ({
    fullPayload,
    prescriptionName,
    orderStatus,
    refillsLeft,
    rxNumber,
    rxDisplayStatuses,
    orderDisplayStatuses,
    ctas,
    setIsRxBusy,
    orderSubStatus,
    isTrackOrder
}: PrescriptionCardModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const drugDescription = useSelector(drugDescriptionSelector);
    const hasInsurance = useSelector(accountHasInsuranceSelector);
    const [descrLookupError, setDescrLookupError] = useState('');

    // Extract the body from the drug description HTML block.
    const drugDescriptionBody = useMemo(() => {
        const drugDescriptionHtml = document.createElement('html');
        drugDescriptionHtml.innerHTML = drugDescription;

        return drugDescriptionHtml.getElementsByTagName('body')[0];
    }, [drugDescription]);

    const renderOrderDisplayStatus = useCallback(() => {
        if (orderDisplayStatuses.length === 0) {
            return '';
        }

        return orderDisplayStatuses.map((item) => item.status);
    }, [orderDisplayStatuses]);

    const renderRxDisplayStatus = useCallback(() => {
        if (rxDisplayStatuses.length === 0) {
            return '';
        }

        return rxDisplayStatuses.map((item) => item.status);
    }, [rxDisplayStatuses]);

    // Build an array of content to display in the details section.
    const detailFields = useMemo(() => {
        return [
            {
                label: t('components.prescriptionCardModal.orderStatus'),
                data: orderSubStatus
            },
            {
                label: t('components.prescriptionCardModal.strength'),
                data: fullPayload.dispensedProductStrength
                    ? fullPayload.dispensedProductStrength +
                      (fullPayload.dispensedProductUnitOfMeasure
                          ? fullPayload.dispensedProductUnitOfMeasure.toLowerCase()
                          : '')
                    : null
            },
            {
                label: t('components.prescriptionCardModal.form'),
                data: fullPayload.dispensedProductDosageForm ? fullPayload.dispensedProductDosageForm.toLowerCase() : ''
            },
            {
                label: t('components.prescriptionCardModal.package'),
                data:
                    (fullPayload?.isUou || fullPayload?.isException) && fullPayload?.packageSize
                        ? fullPayload?.packageSize.toString()
                        : ''
            },
            {
                label: t('components.prescriptionCardModal.quantity'),
                data: fullPayload.fillQuantity
            },
            {
                label: t('components.prescriptionCardModal.directions'),
                data: fullPayload.directions
            },
            {
                label: t('components.prescriptionCardModal.prescribedRefills'),
                data: fullPayload.numRefills
            },
            {
                label: t('components.prescriptionCardModal.refillsLeft'),
                data: refillsLeft
            },
            {
                label: t('components.prescriptionCardModal.lastCoPay'),
                data: hasInsurance ? fullPayload.patientCopay : ''
            },
            {
                label: t('components.prescriptionCardModal.prescribedBy'),
                data: fullPayload.doctorName
            },
            {
                label: t('components.prescriptionCardModal.prescriberPhone'),
                data: fullPayload.doctorPhone
            },
            {
                label: t('components.prescriptionCardModal.prescribedOn'),
                data: fullPayload.writtenDate
            },
            {
                label: t('components.prescriptionCardModal.lastFillDate'),
                data: fullPayload.lastFillDate
            },
            {
                label: t('components.prescriptionCardModal.nextFillDate'),
                data: ['ORDERED', 'IN_CART', 'NEW_RX', 'NO_REFILLS', 'PENDING'].includes(orderStatus)
                    ? ''
                    : fullPayload.nextFillDate
            },
            {
                label: t('components.prescriptionCardModal.expiresOn'),
                data: fullPayload.rxExpirationDate
            },
            {
                label: t('components.prescriptionCardModal.daw'),
                data: fullPayload.dawDesc === 'No DAW(0)' ? '' : fullPayload.dawDesc
            }
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fullPayload.dawDesc,
        fullPayload.directions,
        fullPayload.dispensedProductDosageForm,
        fullPayload.dispensedProductStrength,
        fullPayload.dispensedProductUnitOfMeasure,
        fullPayload.doctorName,
        fullPayload.doctorPhone,
        fullPayload.fillQuantity,
        fullPayload?.isException,
        fullPayload?.isUou,
        fullPayload.lastFillDate,
        fullPayload.nextFillDate,
        fullPayload.numRefills,
        fullPayload?.packageSize,
        fullPayload.patientCopay,
        fullPayload.rxExpirationDate,
        fullPayload.writtenDate,
        hasInsurance,
        refillsLeft,
        renderOrderDisplayStatus,
        t
    ]);

    // Fetch the drug description when the modal is first loaded.
    useEffect(() => {
        // First, reset it so that we don't end up showing the description from
        // a previously rendered modal.
        // TODO - Is there a better pattern for "resetting" something coming from
        // the redux store?
        dispatch(resetDrugDescription());

        // Fetch the description for this drug.
        dispatch(
            drugDescriptionRoutine.trigger({
                gpi: fullPayload.genericProductIndicator,
                onFailure: () => setDescrLookupError(t('components.prescriptionCardModal.noInfoAvail'))
            })
        );
    }, []);

    const DetailRow = ({ label, data }: { label: string; data: string | React.ReactNode }) => {
        if (data && data !== '') {
            return (
                <tr>
                    <th>{label}</th>
                    <td>{data}</td>
                </tr>
            );
        }

        return null;
    };

    // Refresh the medicine cabinet and show the success modal after a prescription
    // is cancelled.
    const showSuccessModal = () => {
        const successModalContent = (
            <BirdiModalContent
                icon={'success'}
                title={t('components.prescriptionCardModal.cancelRx.successTitle')}
                body={t('components.prescriptionCardModal.cancelRx.successBody')}
            />
        );

        // Update prescriptions.
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));

        // Show the success modal.
        dispatch(
            openModal({
                showClose: true,
                bodyContent: successModalContent,
                ctas: [
                    {
                        label: t('components.prescriptionCardModal.cancelRx.gotIt'),
                        variant: 'primary',
                        onClick: () => dispatch(closeModal({}))
                    }
                ]
            })
        );
    };

    // Show the error modal if a prescription cancellation fails.
    const showErrorModal = () => {
        const errorModalContent = (
            <BirdiModalContent
                icon={'alert'}
                title={t('components.prescriptionCardModal.cancelRx.errorTitle')}
                body={t('components.prescriptionCardModal.cancelRx.errorBody', {
                    prescriptionName: prescriptionName
                })}
            />
        );

        dispatch(
            openModal({
                showClose: true,
                bodyContent: errorModalContent,
                ctas: [
                    {
                        label: t('components.prescriptionCardModal.cancelRx.tryAgain'),
                        variant: 'primary',
                        onClick: dispatchCancellation,
                        async: true
                    },
                    {
                        label: t('components.prescriptionCardModal.cancelRx.nevermind'),
                        variant: 'ghost',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    // Dispatch a prescription cancellation. This is called both by the initial
    // cancel modal and in the error modal if/when the user wants to try again.
    const dispatchCancellation = () => {
        // First set the busy state on the modal so that we get the spinner.

        // Then dispatch the cancellation.
        dispatch(setBusyModal(true));
        dispatch(
            cancelPrescriptionRoutine.trigger({
                rxNumber: rxNumber,
                onSuccess: showSuccessModal,
                onFailure: showErrorModal
            })
        );
    };

    return (
        <Container
            fluid
            className={`prescription-card-modal__body h-100 ${orderStatus.toLowerCase().replace(/ /g, '_')}`}
        >
            <Row className={'h-100 p-0'}>
                <Col xs={12} className={'p-0'}>
                    <Row className="prescription-card-modal__heading m-0">
                        <div className={`prescription-status ${orderStatus.toLowerCase().replace(/ /g, '_')}`} />
                        <div
                            className={`prescription-card-modal__icon-container ${orderStatus
                                .toLowerCase()
                                .replace(/ /g, '_')}`}
                        >
                            <PrescriptionCardIcon variant={orderStatus} orderSubStatus={orderSubStatus} t={t} />
                            <div className={`icon-status ${orderStatus.toLowerCase().replace(/ /g, '_')}`}>
                                {renderRxDisplayStatus()}
                            </div>
                        </div>
                    </Row>
                    <div className="prescription-card-modal__content">
                        <div className="prescription-card-modal__content-container">
                            <div className="prescription-card-modal__title-container">
                                <h2 className="h4 prescription-card-modal__title">{prescriptionName.toLowerCase()}</h2>
                                <div className="prescription-card-modal__subtitle">
                                    {t('components.prescriptionCardModal.rxNumberHeaderText', { number: rxNumber })}
                                </div>
                            </div>

                            {isTrackOrder ? (
                                <>
                                    {fullPayload.rxSubStatus?.TrackingNumber && (
                                        <div className="prescription-card-modal__track-order">
                                            <h3 className="prescription-card-modal__section-title">
                                                {t('components.prescriptionCardModal.trackYourOrder')}
                                            </h3>
                                            <p>
                                                <span>{t('components.prescriptionCardModal.trackingId')}</span>
                                                {fullPayload.rxSubStatus?.TrackingNumber}
                                            </p>
                                            {fullPayload.rxSubStatus?.CarrierName && (
                                                <p>
                                                    <span>{t('components.prescriptionCardModal.trackingCarrier')}</span>
                                                    {fullPayload.rxSubStatus?.CarrierName}
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    {fullPayload.rxSubStatus?.RxInProgressStatus && orderStatus !== 'IN_CART' && (
                                        <div className="prescription-card-modal__order-tracker">
                                            <OrderTracker rx={fullPayload} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <h3 className="prescription-card-modal__section-title">
                                        {t('components.prescriptionCardModal.prescriptionDetails')}
                                    </h3>
                                    <table className="prescription-card-modal__details mb-3">
                                        <tbody>
                                            {detailFields.map((item, index) => {
                                                return <DetailRow key={index} data={item.data} label={item.label} />;
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="prescription-card-modal__info">
                                        <DrugInfo
                                            title={t('components.prescriptionCardModal.drugInfo')}
                                            error={descrLookupError}
                                            details={drugDescriptionBody.innerHTML}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Col>

                <Col xs={12} className={'align-self-end prescription-card-modal__bottom'}>
                    <div className="prescription-card-modal__bottom-content">
                        {ctas &&
                            ctas.map((cta, idx) => (
                                <Button
                                    key={`prescription-card-cta-button-${idx}`}
                                    {...cta}
                                    className="btn-bold no-min-width"
                                    type={'button'}
                                    async
                                    onClick={() => {
                                        dispatch(closeModal({}));
                                        setIsRxBusy(true);
                                        cta.onClick();
                                    }}
                                    disabled={cta.disabled}
                                    dataGALocation="PrescriptionCardModal"
                                    dataGAType="modal"
                                    variant={cta.disabled ? 'outline-silver' : 'outline-primary'}
                                />
                            ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PrescriptionCardModal;
