import classNames from 'classnames';
import { navigate } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import sanitizeHtml from 'sanitize-html';

// Components & UI
import Button from 'ui-kit/button/button';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';
import Spinner from 'ui-kit/spinner/spinner';

import { AutoRefillToggle } from 'components/auto-refill-toggle';
import { PrescriptionDetails } from 'components/prescription-details';
import { PrescriptionStatusBox } from 'components/prescription-status-box';
import { PrescriptionStatuses } from 'components/prescription-statuses';

import { noop } from 'util/function';
import { canRxBeCancelled, isRxCloseToExpire, isRxExpired, isRxExpiresToday } from 'util/prescription';

import './prescription-card-mobile.style.scss';
import PrescriptionCardIcon from './prescription-card.icon';
// Prescription Components & Utils
import { PrescriptionCardDisplay } from './types';

const PrescriptionCardMobile: React.FC<PrescriptionCardDisplay> = ({
    isCollapsed,
    orderStatus,
    orderSubStatus,
    fullPayload,
    rxAutoRefillEnabled,
    handleAutoRefillInCard,
    autoRefillToggleBusy,
    rxNumber,
    prescriptionName,
    handleInfoClick,
    isOrderProcessing,
    details,
    rxDisplayStatuses,
    orderDisplayStatuses,
    orderSubStatusLoading,
    refillsLeft,
    rxNextRefillStatuses,
    rxCardFootNote,
    profileObject,
    accountHasInsurance,
    price,
    ctas,
    setIsRxBusy,
    isRxBusy,
    isAddingToCart,
    className,
    isOrderedStatus,
    isOrderStatusVisible,
    isOrderSubStatusProcessing,
    isCaliforniaUser,
    isBirdiSelect,
    handleCancelRxClick,
    showRefillsLeft,
    autoRefillEligibleStatus
}) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const orderStatusClassName = orderStatus.toLowerCase().replace(/\/|\s/g, '_');
    const classes = classNames('prescription-card-v2 mobile', orderStatusClassName, className, {
        collapsed: isCollapsed
    });
    const nexRefillContainerClasses = classNames('prescription-card__rx-refills-container', {
        'prescription-card__refill-due': rxNextRefillStatuses[0]?.isRefillDue && isCollapsed
    });
    const prescriptionCardRefillsOrderClasses = classNames('prescription-card__refills-order', {
        'prescription-card__refill-due': rxNextRefillStatuses[0]?.isRefillDue && !isCollapsed
    });

    let mobileDetails = details;
    if (isCollapsed) {
        mobileDetails = [
            ...[details[1]],
            {
                detail: (
                    <Button
                        className="btn-text-blue-light btn-text"
                        type="button"
                        variant="link"
                        label={t('components.prescriptionCard.drugInfo')}
                        onClick={() => handleInfoClick(false)}
                    />
                )
            }
        ];
    }

    return (
        <div className={classes}>
            {/* Content Section */}
            <div className="prescription-card__content-container">
                <div className="prescription-card__content-details">
                    {/* Order Status */}
                    <PrescriptionStatusBox statuses={rxDisplayStatuses} />

                    {/* Prescription Name */}
                    <div className="prescription-card__title-info-container">
                        {/* Icon */}
                        {!isCollapsed && (
                            <div className="prescription-card__icon_container">
                                <CSSTransition
                                    nodeRef={nodeRef}
                                    in={!!orderSubStatus.orderStatus}
                                    timeout={4000}
                                    classNames="prescription-card-icon"
                                >
                                    <div
                                        ref={nodeRef}
                                        className={'prescription-card__icon-badge-new'}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={noop}
                                    >
                                        <PrescriptionCardIcon
                                            variant={orderStatus}
                                            orderSubStatus={orderSubStatus.orderStatus}
                                            t={t}
                                        />
                                    </div>
                                </CSSTransition>
                            </div>
                        )}

                        <h4 className="prescription-card__title" title={prescriptionName?.toUpperCase()}>
                            {prescriptionName}
                            {/* Plan Badge */}
                            {isBirdiSelect && <PlanBadge variant="birdi-select-blue" />}
                        </h4>
                    </div>

                    {/* RX Number & Details */}
                    <div className="prescription-card__rx-details-container">
                        <PrescriptionDetails details={mobileDetails} expanded={false} />
                    </div>

                    {/* Refill & Order Statuses Mobile */}
                    <div className={prescriptionCardRefillsOrderClasses}>
                        {/* Refill Status */}
                        {
                            /*!isRxWhiteCard(orderStatus)*/ showRefillsLeft && (
                                <div
                                    className={classNames('prescription-card__refills', {
                                        'prescription-card__refills--empty': refillsLeft === 0
                                    })}
                                >
                                    {!isCollapsed && (
                                        <div className="prescription-card__refills-label">
                                            {refillsLeft > 0 && (
                                                <span>{t('components.prescriptionCard.orderRefillAva')}:</span>
                                            )}
                                        </div>
                                    )}
                                    <div>
                                        <span className={refillsLeft === 1 ? 'text-warning' : ''}>
                                            {refillsLeft} {t('components.prescriptionCard.refillsLeftTitle')}
                                        </span>
                                    </div>
                                </div>
                            )
                        }

                        {/* Next Refill */}
                        <div className={nexRefillContainerClasses}>
                            <div>
                                {rxNextRefillStatuses.length > 0 && (
                                    <div className="prescription-card__next-refill">
                                        <div className="prescription-card__details-status-headings">
                                            {rxNextRefillStatuses[0]?.isRefillDue ? (
                                                <>
                                                    {!isCollapsed && (
                                                        <div className="icon">
                                                            <CircleInfo />
                                                        </div>
                                                    )}
                                                    {t(
                                                        `components.prescriptionCard.${
                                                            isCollapsed ? 'orderRefillDueCollapsed' : 'orderRefillDue'
                                                        }`
                                                    )}
                                                </>
                                            ) : (
                                                t(
                                                    `components.prescriptionCard.${
                                                        isCollapsed ? 'orderNextRefillCollapsed' : 'orderNextRefill'
                                                    }`
                                                )
                                            )}
                                        </div>
                                        <PrescriptionStatuses statuses={rxNextRefillStatuses} />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Last Refill */}
                        {!!fullPayload.lastFillDate && (orderStatus === 'EXPIRED' || orderStatus === 'OUT_OF_REFILLS') && (
                            <div className="prescription-card__rx-refills-container">
                                <div className="prescription-card__next-refill">
                                    <div className="prescription-card__refills-label">
                                        <span>
                                            {t(
                                                `components.prescriptionCard.${
                                                    isCollapsed ? 'orderLastRefillCollapsed' : 'orderLastFilled'
                                                }`
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <strong>{fullPayload.lastFillDate}</strong>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Order Statuses */}
                        {isOrderStatusVisible && (
                            <div className="prescription-card__rx-order-statuses">
                                {orderDisplayStatuses.length > 0 && (
                                    <div className="prescription-card__rx-statuses-container">
                                        <div className="prescription-card__rx-statuses">
                                            {orderSubStatusLoading ? (
                                                <>
                                                    <span className="prescription-card__status">
                                                        {t('components.prescriptionCard.fetchingOrderStatus')}
                                                        <Spinner
                                                            isVisible
                                                            t={t}
                                                            fullOverlay={false}
                                                            isSmall
                                                            className="prescription-card__loading-spinner"
                                                        />
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {orderSubStatus.orderStatus !== '' && (
                                                        <>
                                                            <span className="prescription-card__status-label">
                                                                {t('components.prescriptionCard.orderStatusLabel')}
                                                            </span>
                                                            <ul className="prescription-card__status-list prescription-card__order-status-list">
                                                                <li className="prescription-card__status">
                                                                    {orderSubStatus.orderStatus}
                                                                </li>
                                                                {orderSubStatus.subStatus && (
                                                                    <li
                                                                        className={`prescription-card__order-sub-status ${
                                                                            isOrderSubStatusProcessing
                                                                                ? 'processing'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {orderSubStatus.subStatus}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {rxCardFootNote && (
                                    <div className="prescription-card__expired-container">
                                        <div className="prescription-card__rx-expired-note">
                                            <span
                                                className="text-dark"
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(rxCardFootNote, {
                                                        allowedTags: ['strong']
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Cart & Autorefill */}
            <div className="prescription-card__cart_container">
                {ENABLE_AUTO_REFILL &&
                    !isCollapsed &&
                    fullPayload.autoRefillEligible !== null &&
                    autoRefillEligibleStatus && (
                        <AutoRefillToggle
                            checked={rxAutoRefillEnabled}
                            onChange={(val: boolean, isRenew?: boolean) => handleAutoRefillInCard(val, isRenew)}
                            t={t}
                            rxEligible={fullPayload.autoRefillEligible && autoRefillEligibleStatus}
                            isBusy={autoRefillToggleBusy}
                            isConsentExpiresSoon={
                                isRxCloseToExpire(fullPayload.consentExpiration) ||
                                isRxExpiresToday(fullPayload.consentExpiration)
                            }
                            isConsentExpired={isRxExpired(fullPayload.consentExpiration)}
                            isCaliforniaUser={isCaliforniaUser}
                        />
                    )}
                {ctas?.map((cta, idx) => (
                    <Button
                        className="sm-full"
                        key={`prescription-card-cta-button-${idx}`}
                        {...cta}
                        type={'button'}
                        async
                        onClick={() => {
                            // @TODO: On ticket DRX-2083 we remove the use of isRxBusy because it was set to true and was not set to false.
                            // If this behavior is correct and we don't need more this setIsRxBusy anymore, please, refactor to remove it.
                            setIsRxBusy(true);
                            cta.onClick();
                        }}
                        isBusy={isAddingToCart}
                        disabled={isAddingToCart || cta.disabled}
                        dataGALocation="MedicineCabinet"
                        variant={cta.disabled ? 'outline' : 'outline-primary'}
                    />
                ))}
            </div>

            {/* CTAs */}
            {!isCollapsed && (
                <div className="prescription-card__cta_container">
                    <Button
                        className="btn-text-blue-light btn-text"
                        type="button"
                        variant="link"
                        label={t('components.prescriptionCard.drugInfo')}
                        onClick={() => handleInfoClick(false)}
                    />
                    {isOrderedStatus && (
                        <>
                            <span className="divider">|</span>
                            <Button
                                className="btn-text-blue-light btn-text"
                                type="button"
                                variant={'link'}
                                label={t('components.prescriptionCard.trackOrder')}
                                onClick={() => handleInfoClick(true)}
                            />
                        </>
                    )}
                    {orderStatus !== 'NEW_RX' && (
                        <>
                            <span className="divider">|</span>
                            <Button
                                className="btn-text-blue-light btn-text"
                                type="button"
                                variant="link"
                                label={t('components.prescriptionCard.orderHistory')}
                                onClick={() => {
                                    navigate('/secure/order/history');
                                }}
                            />
                        </>
                    )}
                    {canRxBeCancelled(fullPayload, orderStatus) && (
                        <>
                            <span className="divider">|</span>
                            <Button
                                className="btn-text-blue-light btn-text"
                                type="button"
                                variant="link"
                                label={t('components.prescriptionCard.remove')}
                                onClick={handleCancelRxClick}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default PrescriptionCardMobile;
